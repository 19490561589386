<template>
  <el-popover :width="width"
              :placement="placement"
              :trigger="trigger"
              @show="getDictValue(shard, value)">

    <template>

      <div v-if="showJson">
        <pre v-if="info" v-html="info"></pre>
        <div v-else>暂无数据</div>
      </div>

      <div v-else>
        <div v-if="dict && showDict" class="dict-value">原文：</div>
        <div>{{value}}</div>
      </div>

      <div v-if="dict && showDict"
           style="margin-top:10px">
        <div class="dict-value">译文：</div>
        <div v-if="dictValue.length !== 0">
          <div v-for="(it,index) in dictValue"
               :key="index">{{it.dictValue}}</div>
        </div>
        <div v-else>暂无译文</div>
      </div>
    </template>

    <div slot="reference">
      <div v-if="isSubStr"
           :class="divClass"
           :style="divStyle">{{ behindStr(value, num) }}</div>
      <div v-else
           :class="divClass"
           :style="divStyle">{{ value }}</div>
    </div>
  </el-popover>
</template>

<script>
import publicFunction from '@/utils/publicFunction.js'
// import deepClone from '@/utils/deepClone.js'
import { activeArray } from '@/utils/localStorage.js'
export default {
  name: 'popover',
  data() {
    return {
      dict: activeArray('/dict/dict-list-(\\d+)-(\\d+)'),
      dictValue: [], // 译文
      info: ''
    }
  },
  props: {
    // 是否显示译文
    showDict: {
      type: Boolean,
      default: true
    },
    // 是否截取字符串
    isSubStr: {
      type: Boolean,
      default: true
    },
    showJson: {
      type: Boolean,
      default: false
    },
    shard: {
      type: String,
      default: null
    },
    value: {
      type: [String, Object, Array]
    },
    width: {
      type: Number,
      default: 500
    },
    placement: {
      type: String,
      default: 'top-start'
    },
    trigger: {
      type: String,
      default: 'hover'
    },
    // 整体样式
    divStyle: {
      type: Array,
      default: () => {
        return []
      }
    },
    divClass: {
      type: String,
      default: ''
    },
    minWidth: {
      type: Number,
      default: 0
    },
    divWidth: {
      type: Number,
      default: 0
    },
    num: {
      type: Number,
      default: 0
    },
    color: {
      type: String,
      default: ''
    }
  },
  mounted() {
    if (this.showJson) {
      this.getInfo(this.value)
    }
  },
  methods: {
    // 判断接口权限
    getAPI() {
      return activeArray('/dict/dict-list-(\\d+)-(\\d+)')
    },
    // 译文查询
    getDictValue(shardName, key) {
      // 进行匹配
      let body = {
        shardName: shardName,
        dictKey: key
      }
      let param = {
        page: 0,
        size: 1
      }
      // 判断是否有字典接口权限
      if (activeArray('/dict/dict-list-(\\d+)-(\\d+)') && this.showDict) {
        this.$api.dictionary.shardData(param, body).then((res) => {
          if (res.code === 200) {
            this.dictValue = res.data.content
            console.log(this.dictValue)
          }
        })
      }
    },
    getInfo(value) {
      // console.log(JSON.parse(JSON.stringify(value)).name)
      // if (JSON.parse(value) === 'object') {
      this.info = this.syntaxHighlight(JSON.parse(value))
      // } else {
      //   this.info = this.syntaxHighlight(value)
      // }
    },
    // 字段截取
    behindStr(value, number) {
      return publicFunction.behindStr(value, number)
    },
    // 对象格式处理
    syntaxHighlight(json) {
      return publicFunction.syntaxHighlight(json)
    },
    // 判断所传参数的数据类型
    judgeType(obj) {
      // tostring会返回对应不同的标签的构造函数
      const toString = Object.prototype.toString
      const map = {
        '[object Boolean]': 'boolean',
        '[object Number]': 'number',
        '[object String]': 'string',
        '[object Function]': 'function',
        '[object Array]': 'array',
        '[object Date]': 'date',
        '[object RegExp]': 'regExp',
        '[object Undefined]': 'undefined',
        '[object Null]': 'null',
        '[object Object]': 'object'
      }
      if (obj instanceof Element) {
        return 'element'
      }
      return map[toString.call(obj)]
    }
  }
}
</script>

<style lang="scss" scoped>
.dict-value {
  font-weight: bold;
  color: #3f536e;
  margin-bottom: 10px;
}
// 不换行省略
.shenglue {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.details-content {
  position: relative;
  top: 28px;
  line-height: 20px;
  color: #3f536e;
  word-wrap: break-word;
  height: 80px;
}
// js样式
::v-deep pre {
  min-height: 400px;
  background: rgb(241, 241, 241);
  padding: 10px;
  margin: 10px;
  font-size: 16px;
  overflow-x: scroll;
  .string {
    color: green;
  }
  .number {
    color: darkorange;
  }
  .boolean {
    color: blue;
  }
  .null {
    color: magenta;
  }
  .key {
    color: red;
  }
}
</style>
